// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-le-relais-de-lavance-js": () => import("./../../../src/pages/contact-le-relais-de-lavance.js" /* webpackChunkName: "component---src-pages-contact-le-relais-de-lavance-js" */),
  "component---src-pages-epicerie-le-relais-de-lavance-js": () => import("./../../../src/pages/epicerie-le-relais-de-lavance.js" /* webpackChunkName: "component---src-pages-epicerie-le-relais-de-lavance-js" */),
  "component---src-pages-horaires-le-relais-de-lavance-js": () => import("./../../../src/pages/horaires-le-relais-de-lavance.js" /* webpackChunkName: "component---src-pages-horaires-le-relais-de-lavance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-restaurant-le-relais-de-lavance-js": () => import("./../../../src/pages/restaurant-le-relais-de-lavance.js" /* webpackChunkName: "component---src-pages-restaurant-le-relais-de-lavance-js" */),
  "component---src-pages-traiteur-mariage-hautes-alpes-js": () => import("./../../../src/pages/traiteur-mariage-hautes-alpes.js" /* webpackChunkName: "component---src-pages-traiteur-mariage-hautes-alpes-js" */),
  "component---src-templates-actualites-js": () => import("./../../../src/templates/actualites.js" /* webpackChunkName: "component---src-templates-actualites-js" */)
}

